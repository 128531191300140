import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';

const OfferPage = () => {
    return (
        <Layout>
            <Seo title="Oferta"/>
            <section className='offer-container'>
                <section className='offer-hero'>
                    <div className='offer-hero_name'>
                        <div className='offer-hero_name_text'>
                            <h1 className='offer-hero_name_text_title'>zajęcia indywidualne</h1>
                            <p className='offer-hero_name_text_numerical'>01</p>
                        </div>
                        <div className='offer-hero_name_underline'></div>
                    </div>
                    <div className='offer-hero_main'>
                        <div className='offer-hero_main_info'>
                            <p className='offer-hero_main-info_text'>Zajęcia indywidualne Pilates prowadzimy na maszynach (m.in: Reformer, Cadillac, Ladder Barrel, Wunda Chair, Combo Chair, Spine Corrector) oraz z mniejszym sprzętem i na macie. Program treningów przygotowujemy na podstawie wniosków z diagnostyki oraz indywidualnych celów Klienta.</p>
                            <div className='offer-hero_main-info_link-container'>
                                <Link className='offer-hero_main-info_link-link' to='/oferta-indywidualna'>SZCZEGÓŁY</Link>
                            </div>
                        </div>
                        <div className='offer-hero_main_picture-container'>
                            <StaticImage 
                                src='../images/main_first.jpg'
                                alt="poza pilates na macie" 
                                loading="eager"
                                className='offer-hero_main_picture-picture'
                            />
                        </div>
                    </div>
                </section>
                <section className='offer-hero'>
                    <div className='offer-hero_name'>
                        <div className='offer-hero_name_text'>
                            <h1 className='offer-hero_name_text_title'>duety</h1>
                            <p className='offer-hero_name_text_numerical'>02</p>
                        </div>
                        <div className='offer-hero_name_underline'></div>
                    </div>
                    <div className='offer-hero_main reverse'>
                        <div className='offer-hero_main_info'>
                            <p className='offer-hero_main-info_text'>Zajęcia w duetach (parach) prowadzimy na różnych maszynach oraz na macie ze sprzętem. Każda osoba z duetu zaczyna od indywidualnej sesji diagnostycznej. Podczas wspólnych treningów łączymy indywidualne podejście i dopasowany program ze wzajemną motywacją, fajną atmosferą i wspólnie spędzonym czasem.</p>
                            <div className='offer-hero_main-info_link-container'>
                                <Link className='offer-hero_main-info_link-link' to='/oferta-duety'>SZCZEGÓŁY</Link>
                            </div>
                        </div>
                        <div className='offer-hero_main_picture-container'>
                        <StaticImage 
                            src='../images/main_second.jpg'
                            alt="ćwiczenie pilates" 
                            className='offer-hero_main_picture-picture'
                        />
                        </div>
                    </div>
                </section>
                <section className='offer-hero'>
                    <div className='offer-hero_name'>
                        <div className='offer-hero_name_text'>
                            <h1 className='offer-hero_name_text_title'>oferta dla firm</h1>
                            <p className='offer-hero_name_text_numerical'>03</p>
                        </div>
                        <div className='offer-hero_name_underline'></div>
                    </div>
                    <div className='offer-hero_main'>
                        <div className='offer-hero_main_info'>
                            <p className='offer-hero_main-info_text'>Pracownikom firm oferujemy programy edukacyjne i wellbeingowe w obszarze zdrowia i aktywności fizycznej. Proponujemy m.in.: wykłady edukacyjne, treningi w minigrupach, zajęcia podczas wyjazdów integracyjnych, indywidualne analizy postawy ciała oraz konsultacje ergonomii środowiska pracy.<br/><br/>
                            Każdą ofertę budujemy pod konkretne potrzeby pracowników a także całej organizacji. Prowadzimy dla firm programy stacjonarne oraz on-line.
                            </p>
                            <div className='offer-hero_main-info_link-container'>
                                <Link className='offer-hero_main-info_link-link' to='/oferta-dla-firm'>SZCZEGÓŁY</Link>
                            </div>
                        </div>
                        <div className='offer-hero_main_picture-container'>
                            <StaticImage 
                                src='../images/main_third.jpg'
                                alt="ćwiczenie pilates" 
                                className='offer-hero_main_picture-picture'
                            />
                        </div>
                    </div>
                </section>
                <section className='offer-hero'>
                    <div className='offer-hero_name'>
                        <div className='offer-hero_name_text'>
                            <h1 className='offer-hero_name_text_title'>oferta dla instruktorów</h1>
                            <p className='offer-hero_name_text_numerical'>04</p>
                        </div>
                        <div className='offer-hero_name_underline'></div>
                    </div>
                    <div className='offer-hero_main reverse'>
                        <div className='offer-hero_main_info'>
                            <p className='offer-hero_main-info_text'>Zapraszamy instruktorów różnych metod pracy z ciałem. Nasza oferta obejmuje: kursy, szkolenia, spotkania Mastermind oraz programy partnerskie. Udostępniamy naszą przestrzeń do organizacji własnych wydarzeń. Prowadzimy też program stażowy Pilatesu terapeutycznego i superwizje.</p>
                            <div className='offer-hero_main-info_link-container'>
                                <Link className='offer-hero_main-info_link-link' to='/warsztaty-dla-instruktorow'>SZCZEGÓŁY</Link>
                            </div>
                        </div>
                        <div className='offer-hero_main_picture-container'>
                            <StaticImage 
                                src='../images/main_fourth.jpg'
                                alt="ćwiczenie pilates" 
                                className='offer-hero_main_picture-picture'
                            />
                        </div>
                    </div>
                </section>
            </section>
        </Layout>
    )
}

export default OfferPage;